import React from 'react'
import { PagesWithNavbar } from '../../common/components'

const About_us = () => {
  return (
    <PagesWithNavbar>
      About Us
    </PagesWithNavbar>
  )
}

export default About_us
