import React from 'react'
import { PagesWithNavbar } from '../../common/components'

const Courses = () => {
  return (
    <PagesWithNavbar>
      Courses
    </PagesWithNavbar>
  )
}

export default Courses